import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
// @ts-ignore need types for it
import VImageInput from 'vuetify-image-input/a-la-carte'
import { OrganizationService } from '@/App/Services/Organization.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { VImageInput }
})
export default class SettingsPage extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  private readonly userInfo: UserInfo

  @sharedStore.Mutation
  private readonly setUserInfo:(user: UserInfo) => void

  public logo: any = null
  public isLoading = false

  public async onUpload() {
    try {
      this.isLoading = true
      const logo = await OrganizationService.uploadLogo(this.userInfo.organizationId, this.logo)

      this.setUserInfo({ ...this.userInfo, logo })

      this.setNotification({ text: this.$t('settingsPage.logoUploadedNotificationText').toString() })
    } catch (e) {
      this.setNotification({ text: this.$t('settingsPage.errorUploadingLogoNotificationText').toString(), color: 'error', timeout: 5000 })
    } finally {
      this.isLoading = false
    }
  }
}
